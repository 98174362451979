<template>
  <section class="tables">
     
    
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">Tous les zones</div>
               <nav aria-label="breadcrumb" class="float-right">
              <ol class="breadcrumb">
                <router-link to="addzone">
                  <li class="breadcrumb-item btn btn-primary">
                    Ajouter une zone
                  </li>
                </router-link>
              </ol>
            </nav>
            
            <div class="table-responsive">
              <input
                value=""
                class="form-control search-input"
                placeholder="Recherche par intitulé"
                type="text"
                v-model="search"
              />
            <table class="table">
              <thead>
                <tr>
                  <th>Intitulé</th>
                  <th>Lattitude</th>
                  <th>Longitude</th>
                   <th>Gouvernerat</th>
                  <th>Municipalité</th>
                  <th>Délégation</th>
                  <th>Actions</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="(zone, key) in zones" :key="key">
                  <td>{{ zone.intitule }}</td>
                  <td>{{ zone.lattitude }}</td>
                  <td>{{ zone.longitude }}</td>
                   <td>{{ zone.gouvernerat }}</td>
                  <td>{{ zone.municipalite }}</td>
                  <td>{{ zone.delegation }}</td>

                  <td >
                    <router-link
                      :to="{ name: 'updatezone', params: { id: zone._id } }"
                      ><i 
                      class="mdi mdi-pen icone text-success" style="color:green"></i></router-link
                    >&nbsp;
                    <i 
                      class="mdi mdi-delete icone text-danger"  style="color:red"
                      @click="deleteproduit(zone._id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from '@/axios';
import Swal from "sweetalert2";
import Toast from "sweetalert2";
import axios from 'axios';
export default {
  data() {
    return {
      zones: [],
      search:"",
    };
  },


      computed: {
    searchFunction() {
      return this.zones.filter((item) => {
        return (
          item.intitule
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },

  created() {
    this.getzones();
  },
  methods: {
    getzones() {
      HTTP
        .get("zones/getzones")
        .then((response) => {
          this.zones = response.data;
        })
        .then((err) => console.log(err));
    },

     deleteproduit(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez supprimé la zone",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("zones/deletezone/" + id)
            .then((response) => {
              //this.$htmlToPaper("printNow");

              Toast.fire({
                position: "top-center",
                icon: "success",
                title: "Zone supprimé",
              });

              this.getzones();
            });
        }
      });
    },
  },
};
</script>
